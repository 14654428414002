<template>
  <div class="tb">
    <el-table v-show="tableData.length>0" :data="tableData" style="width: 100%" @sort-change="handleSortChange"
      :header-cell-class-name="handleTheadStyle">
      <el-table-column prop="info" label="共找到" width="700">
        <template #header>
          <div class="title">共找到{{total}}位优选海师</div>
        </template>
        <template slot-scope="scope">
          <div class="table_body_info">
            <div class="info_type">{{ scope.row.hd_hdsf_one|- }}</div>
            <img class="info_touxiang" :src="scope.row.hstp" alt="" @click="chakan(scope.row.id)"/>
            <div class="info_gather">
              <div class="info_gather_name">
                <p>{{ scope.row.name|- }}</p>
                <i class="iconfont xhgactive" title="活跃" v-show="scope.row.hd_zt=='活跃'"></i>
                <span v-show="scope.row.hd_wsjl != null" :title="scope.row.hd_wsjl">{{
                  scope.row.hd_wsjl|-
                }}</span>
              </div>
              <div class="info_gather_school">
                <p>{{ scope.row.school.zwmc|- }}</p>
                <div>
                  {{ scope.row.school.education|- }} | {{ scope.row.major|- }}
                </div>
              </div>
              <div class="info_gather_yycj">
                <div v-for="(item, key) in scope.row.hd_yycj" :key="key">
                  <span :title="item">{{ item|- }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hd_ztdf"  align="center">
        <template >
          <div class="table_body_fwxs"> 
            <!-- <p>{{ scope.row.hd_num }}</p>
            <span>服务学生</span> -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hd_ztdf" label="按评分排序" align="center" sortable="custom">
        <template slot-scope="scope">
          <div class="table_body_fwxs">
            <p>{{ scope.row.hd_ztdf }}</p>
            <span>综合评分</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="hd_zt"
        label="按活跃度排序"
        width="180"
        align="center"
        sortable="custom"
      >
        <template slot-scope="scope">
          <div class="table_body_btn">
            <span @click="yuyue(scope.row.id)">预约</span>
            <span @click="chakan(scope.row.id)">查看</span>
            <span @click="share(scope.row.id)">分享</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="null_data" v-show="tableData.length<=0">
      <img src="./images/null.png" alt="">
      <p>未找到匹配的海师</p>
    </div>
    <share v-if="flag" @share="share" :share_url="share_url" />
  </div>
</template>

<script>
import share from '@/views/haishi/components/share'
export default {
  props: ["tableData",'total'],
  components:{share},
  data(){
    return{
      flag:false,
      total2:'',
      share_url:"",
      curThead: [{ order: '', prop: 'hd_ztdf' }, { order: '', prop: 'hd_zt' }, { order: '', prop: 'hd_num' }]
    }
  },
  created(){
    this.total2 = this.total
  },
  methods:{
    chakan(id){
      // $router.push(`/haishi/haishiDetails?id=${scope.row.id}`)
      const { href } = this.$router.resolve({
            path: "/haishi/haishiDetails",
            query: {
                id: id
            }
        });
        window.open(href, '_blank');
    },
    yuyue(id){
      if(this.$cookie.get('infor')){
        // this.$router.push(`/haishi/haishiSub?id=${id}`)
        const { href } = this.$router.resolve({
            path: "/haishi/haishiSub",
            query: {
                id: id
            }
        });
        window.open(href, '_blank');
      }else{
        this.$store.state.login = true
      }
    },
    //分享
    share(id){
      if(id){
        this.share_url = `${this.$store.state.currentUrl}/#/MhsDetail?id=${id}`
      }
      this.flag = !this.flag
    },
    // 表头切换 class事件
    handleTheadStyle ({ row, column, rowIndex, columnIndex }) {
      // 找出当前节点的是否在curThead有所记录
      const index = this.curThead.findIndex(item => { return item.prop == column.property })
      if (index > -1) {
        // 在curThead找到当前节点的property值，对其order进行赋值。
        // 值得注意的是，column中property字段保存的是当前节点的prop值。
        column.order = this.curThead[index].order
      }
    },
    // 表格排序
    handleSortChange ({ column, prop, order }) {
      // 判断数组curThead中是否存在当前节点的prop
      const index = this.curThead.findIndex(item => { return item.prop == prop })
      if (index > -1) {
        // 如果存在，修改数组curThead中的order
        this.curThead[index].order = order
      } else {
        // 如果不存在，向数组curThead中添加当前节点的prop和order
        this.curThead.push({ prop: prop, order: order })
      }
      console.log(this.curThead)
      this.$emit('tableSort', this.curThead)
    }
  }
};
</script>

<style lang="scss" scoped>
//表格
.tb {
  /deep/ .el-table {
    background: none;
    &::before {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
    }
    //表格header
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          background: none;
          th {
            height: 78px;
            border: none;
            background: none;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            .cell {
              padding: 0px;
              .title {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
            }
          }
        }
      }
    }
    //表格body
    .el-table__body-wrapper {
      border-radius: 0px 8px 0 0;
      background: white;
      td {
        padding: 0px;
        .cell {
          padding: 0px;
        }
      }
    }
    .ascending .sort-caret.ascending{
      border-bottom-color:rgb(217, 157, 102);
    }
    .descending .sort-caret.descending{
      border-top-color:rgb(217, 157, 102);
    }
  }
  //自定义列
  .table_body_info {
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0;
    height: 170px;
    .info_type {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 12px;
      color: #935d14;
      padding: 0 8px;
      background: linear-gradient(270deg, #f8e2c4 0%, #f3bc70 100%);
      border-radius: 0px 0px 8px 0px;
    }
    .info_touxiang {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 0 20px;
      flex-shrink: 0;
      object-fit: cover;
    }
    .info_gather {
      .info_gather_name {
        display: flex;
        align-items: center;
        white-space: nowrap;
        // overflow: hidden;
        text-overflow: ellipsis;
        p {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-right: 10px;
        }
        i {
          color: #f4523b;
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          border-radius: 4px;
          border: 1px solid #f5f5f5;
          padding: 0 6px;
          margin-right: 10px;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .info_gather_school {
        display: flex;
        align-items: center;
        margin-top: 10px;
        p {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
        }
        div {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
      .info_gather_yycj {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        div {
          position: relative;
          margin-right: 15px;
        }
        span {
          display: inline-block;
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          background: #f8ece1;
          font-size: 14px;
          font-weight: 500;
          color: #d99d66;
          padding: 2px 0px;
          padding-left: 8px;
          padding-right: 10px;
          border-radius: 4px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
  .table_body_fwxs {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 26px;
      font-weight: 500;
      color: #ff8a3c;
      line-height: 33px;
      margin-bottom: 15px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
    }
  }
  .table_body_btn {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0px;
    span {
      display: inline-block;
      width: 90px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #cccccc;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 38px;
      cursor: pointer;
    }
    span:hover {
      background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
      border-radius: 4px;
      color: #935d14;
      border: 1px solid transparent;
    }
  }

  //无数据
  .null_data{
    margin-top: 20px;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex-direction: column;

    font-size: 16px;
    font-weight: 400;
    color: #B1C2D9;
    img{
      width: 200px;
    }
  }
}
</style>
