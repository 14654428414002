<template>
  <div class="haishiList">
    <!-- 海师类别统计 -->
    <lbtj :hslbtj="hslbtj" />
    <!-- 筛选项 -->
    <div class="shaixuan">
      <div class="shaixuan_bxo1">
        <div class="shaixuan_bxo1_item">
          <span>海师身份</span>
          <div>
            <el-radio-group
              v-model="form1.hssfr"
              @change="hssfChange(form1.hssfr, 'hssfr')"
            >
              <el-radio-button
                v-for="(item, key) in hssfItem.type1"
                :key="key"
                :label="item.title"
              ></el-radio-button>
            </el-radio-group>
            <el-select
              v-for="(item, key) in hssfItem.type2"
              :key="key"
              v-model="form1['hssf' + key]"
              width="100%"
              :placeholder="item.title"
              @change="hssfChange(form1['hssf' + key], 'hssf' + key)"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="(items, keys) in item.list"
                :key="keys"
                :label="items"
                :value="items"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="shaixuan_bxo1_item">
          <span>应用场景</span>
          <div>
            <el-radio-group
              v-model="form1.yycjr"
              @change="yycjChange(form1.yycjr, 'yycjr')"
            >
              <el-radio-button
                v-for="(item, key) in yycjItem.type1"
                :key="key"
                :label="item.title"
              ></el-radio-button>
            </el-radio-group>
            <el-select
              v-for="(item, key) in yycjItem.type2"
              :key="key"
              v-model="form1['yycj' + key]"
              width="100%"
              :placeholder="item.title"
              @change="yycjChange(form1['yycj' + key], 'yycj' + key)"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="(items, keys) in item.list"
                :key="keys"
                :label="items"
                :value="items"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="shaixuan_bxo2">
        <span>辅助条件</span>
        <div class="shaixuan_items">
          <el-select
            v-model="form1.guojia"
            placeholder="请选择国家"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in assist.country"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="form1.yxmc"
            placeholder="请输入院校名称"
          ></el-input>
          <el-input v-model="form1.hszy" placeholder="请输入专业"></el-input>
          <el-input
            v-model="form1.skkm"
            placeholder="请输入授课科目"
          ></el-input>
          <el-select
            v-model="form1.hspf"
            placeholder="海师评分"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in assist.score"
              :key="item"
              :label="item+'以上'"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form1.sfhy"
            placeholder="是否活跃"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in assist.active"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form1.hsxl"
            placeholder="海师学历"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in assist.school"
              :key="item.key"
              :label="item.key"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sxbtn">
          <div class="btn" @click="find">查询</div>
          <div class="btn" @click="wholeReset()">重置</div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="tb">
      <Table :tableData='tableData' :total="total" @tableSort="tableSort"></Table>
      <div class="fenye" v-if="tableData.length>0">
        <div class="more" v-if="!this.$cookie.get('token')" @click="$store.state.login = true">登录后可获取更多海师资源</div>
        <el-pagination v-else background layout="prev, pager, next" :total="total" :page-size='limit' @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import lbtj from "../components/lbtj";
import Table from "./components/table";
import { getCondition, getHsList } from "@/api/haishi";
export default {
  components: { lbtj,Table },
  data() {
    return {
      limit:10,//条数
      page:1,//页数
      total:0,//总条数
      curThead: [{ order: '', prop: 'hd_ztdf' }, { order: '', prop: 'hd_zt' }, { order: '', prop: 'hd_num' }],//表格多重排序
      hslbtj: [],//海师类别统计
      //form
      form1: {
        hssf: "", //海师身份
        hssfr: "",//海师身份r
        hssf0: "",//海师身份0
        hssf1: "", //海师身份1
        hssf2: "", //海师身份2
        hssf3: "", //海师身份3
        yycj: "", //应用场景
        yycjr: "",//应用场景r
        yycj0: "",//应用场景0
        yycj1: "",//应用场景1
        yycj2: "",//应用场景2
        yycj3: "",//应用场景3
        guojia: "", //国家
        yxmc: "", //院校名称
        hszy: "", //海师专业
        skkm: "", //授课科目
        hspf: "", //海师评分
        sfhy: "", //是否活跃
        hsxl: "", //海师学历
      },
      //海师身份筛选项
      hssfItem: {
        //类型1
        type1: [],
        //类型2
        type2: [],
      },
      //应用场景筛选项
      yycjItem: {
        //类型1
        type1: [],
        //类型2
        type2: [],
      },
      //辅助条件
      assist: {},
      //列表数据
      tableData: [],
    };
  },
  async mounted() {
    console.log()
    const query = this.$route.query
    if(query.major){
      this.form1.hszy = query.major
    }else if(query.school){
      this.form1.yxmc = query.school
    }
    await getCondition({}).then((res) => {
      // console.log(res)
      this.hssfItem = res.data.hsIdentity; //海师身份类型
      this.yycjItem = res.data.hsApplication; //应用场景
      this.assist = res.data.assist; //辅助条件
      this.hslbtj = res.data.hwdsShow; //海师类别
    });
    await this.getList();
  },
  methods: {
    //列表排序
    tableSort (e) {
      this.curThead = e
      this.getList()
    },
    //获取列表
    async getList() {
      await getHsList({
        search: this.$store.state.search, //搜索
        limit:this.limit,
        page:this.page,
        hd_hdsf: this.form1.hssf, //海师身份
        hd_yycj: this.form1.yycj, //应用场景
        hd_gj: this.form1.guojia, //国家
        school_zwmc: this.form1.yxmc, //院校名称
        major: this.form1.hszy, //专业
        course: this.form1.skkm, //授课科目
        hd_zt: this.form1.sfhy, //是否活跃
        school_education: this.form1.hsxl, //海师学历
        hd_ztdf: this.form1.hspf, //海师评分
        order_score:this.curThead[0].order,//评分排序
        order_active:this.curThead[1].order,//活跃度排序
        order_student:this.curThead[2].order,//服务学生数量排序
      }).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total
      });
    },
    pageChange(e){
      console.log(e)
      this.page = e
      this.getList()
    },
    //find
    find(){
      this.page=1
      this.getList()
    },
    //获取海师身份最终值
    hssfChange(v, i) {
      //全部置空
      this.form1.hssfr = "";
      //如果有类型为2的筛选
      if(this.hssfItem.type2){
        this.hssfItem.type2.forEach((item, key) => {
          this.form1["hssf" + key] = "";
        });
      }
      //当前赋值
      this.form1[i] = v;
      //真海师身份赋值
      this.form1.hssf = v;
    },
    //获取应用场景最终值
    yycjChange(v, i) {
      //全部置空
      this.form1.yycjr = "";
      //如果有类型为2的筛选
      if(this.yycjItem.type2){
         this.yycjItem.type2.forEach((item, key) => {
          this.form1["yycj" + key] = "";
        });
      }

      //当前赋值
      this.form1[i] = v;
      //真海师身份赋值
      this.form1.yycj = v;
    },
    //清空值 重新请求
    wholeReset(){
      this.form1= {
        hssf: "", //海师身份
        hssfr: "",
        hssf0: "",
        hssf1: "", //海师身份
        yycj: "", //应用场景
        yycjr: "",
        yycj0: "",
        yycj1: "",
        guojia: "", //国家
        yxmc: "", //院校名称
        hszy: "", //海师专业
        skkm: "", //授课科目
        hspf: "", //海师评分
        sfhy: "", //是否活跃
        hsxl: "", //海师学历
      }
      this.page = 1
      this.getList()
    }
  },
  watch:{
    '$store.state.search': function (v) {
      this.wholeReset()
    },
    'form1.hssf': function (v) {
      this.getList()
    },
    'form1.yycj': function (v) {
      this.getList()
    },
  }
};
</script>

<style scoped lang="scss">
.haishiList {
  //筛选项
  .shaixuan {
    background: #ffffff;
    border-radius: 8px;
    padding: 0 20px;
    box-sizing: border-box;
    //统一输入和选择框字体颜色、背景
    /deep/ .el-input__inner {
      color: #d99d66;
      background: #fafafa;
      &.el-input__inner:focus {
        border-color: #d99d66;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #505d69;
      }
      &::-moz-input-placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #505d69;
      }
      &::-ms-input-placeholder {
        font-size: 14px;
        font-weight: 500;
        color: #505d69;
      }
    }
    /deep/ .el-select .el-input.is-focus .el-input__inner {
      border-color: #d99d66;
    }
    //下拉列表选中
    /deep/ .el-select {
      .el-select-dropdown {
        .el-select-dropdown__item.selected {
          color: #d99d66;
          font-weight: 500;
        }
      }
    }
    //第一部分筛选项
    .shaixuan_bxo1 {
      border-bottom: 1px solid #ededed;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 10px 0px;
      .shaixuan_bxo1_item {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
        > span {
          display: inline-block;
          height: 48px;
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          padding-right: 20px;
          border-bottom: 2px solid white;
          line-height: 48px;
          box-sizing: border-box;
        }
        > div {
          flex: 1;
          display: flex;
          // align-items: center;
        }
        //单选框
        /deep/ .el-radio-group {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .el-radio-button {
            margin: 10px 0px;
            outline:none !important;
            box-shadow: none !important;
            .el-radio-button__inner {
              font-size: 14px;
              font-weight: 500;
              color: #505d69;
              border: none;
              padding: 6px 0px;
              margin: 0px 0px;
              margin-right: 30px;
              border-radius: 0px;
              box-sizing: border-box;
              border-bottom: 2px solid #ffffff;
              outline:none;
            }
          }
          .is-active {
            color: #d99d66;
            .el-radio-button__inner {
              color: #d99d66;
              background: none;
              box-shadow: none;
              border-bottom: 2px solid #d99d66;
            }
          }
        }
        //下拉框
        /deep/ .el-select {
          margin: 10px 0px;
          margin-right: 30px;
          display: flex;
          height: 27.6px;
          .el-input {
            display: flex;
          }
          .el-input__inner {
            width: 100px !important;
            color: #d99d66;
            border: 1px solid #fff;
            padding: 0px;
            background: none;
            box-shadow: none;
            border-radius: 0px;
            display: inline-block;
            border-bottom: 2px solid white;
            box-sizing: border-box;
            height: 27.6px;
            line-height: 27.6px;
          }
          .el-select__caret {
            line-height: 27px;
          }
        }
      }
    }
    //第二部分筛选项
    .shaixuan_bxo2 {
      display: flex;
      flex-shrink: 0;
      padding: 22px 0px;
      > span {
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        padding-right: 20px;
      }
      .shaixuan_items {
        flex: 1;
        box-sizing: border-box;
        padding-right: 300px;
        /deep/ .el-select {
          .el-input {
            margin-bottom: 15px;
            margin-right: 20px;
          }
          .el-input__inner {
            width: 129px;
            height: 32px;
          }
          .el-select__caret {
            line-height: 32px;
          }
        }
        /deep/ .el-input {
          width: 129px;
          height: 32px;
          margin-right: 20px;
          .el-input__inner {
            width: 129px;
            height: 32px;
          }
        }
      }
      .btn {
        width: 100px;
        height: 40px;
        margin: 10px 0px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;
        position: relative;
      }
      .btn:hover {
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        border-radius: 4px;
        border: none;
        color: #935d14;
      }
      .btn:active {
        opacity: 0.8;
        top: 1px;
      }
    }
  }
  .more{
    font-size: 16px;
    font-weight: 400;
    color: #1890FF;
    border-radius: 4px;
    border: 1px solid rgba(24, 144, 255, 0.3);
    padding: 10px 15px;
    cursor: pointer;
  }
}
</style>
