<template>
  <div class="lbtj">
      <div class="lbtj_item" v-for="(item, key) in hslbtj" :key="key">
        <div class="icon"><i :class="[item.icon, 'iconfont']"></i></div>
        <div class="item_type">
          <span>{{ item.title }}</span>
          <div class="item_num">
            <b>{{ item.count }}</b>
            <div class="progress">
              <span>{{ item.number }}%</span>
              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="item.number"
                :color="colors[key]"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props:['hslbtj'],
  data(){
    return{
      colors:['#F75676','#4FD69C','#4688FF','#FC7C5F','#68C23A','#8675FF'],
    }
  },
  mounted(){
    console.log('组件数据',this.hslbtj);

  }
}
</script>

<style scoped lang='scss'>
//海师类别统计
  .lbtj {
    height: 90px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    .lbtj_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      .icon {
        width: 48px;
        height: 48px;
        background: white;
        border-radius: 2px 8px 2px 8px;
        margin-right: 10px;
        box-shadow: 0px 2px 14px 0px rgba(112, 96, 94, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #d99d66;
        i {
          font-size: 20px;
        }
      }
      .item_type {
        > span {
          font-size: 12px;
          font-weight: 500;
          color: #505d69;
          margin-bottom: 6px;
        }
        .item_num {
          display: flex;
          align-items: flex-end;
          .progress {
            width: 50px;
            text-align: right;
            margin-left: 6px;
            margin-bottom: 2px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #505d69;
            }
            /deep/ .el-progress {
              margin-top: 2px;
              height: 5px !important;
              .el-progress-bar {
                display: block;
              }
            }
            /deep/ .el-progress-bar__outer {
              height: 5px !important;
              .el-progress-bar__innerText {
                display: none;
              }
            }
          }
        }
      }
    }
  }

</style>
